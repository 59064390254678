import { styled, Typography, typographyClasses } from '@mui/material';
import { ReactComponent as Logo } from 'assets/images/logoModal.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from '../CommonStyles';
import { FeedbackReceivedProps } from './models';

const FeedbackReceivedContainer = styled('div')(({ theme: { palette } }) => ({
  textAlign: 'center',

  [`.${typographyClasses.h2}`]: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    lineHeight: '2.8rem',
    color: palette.text.primary,
    marginTop: 10,
    marginBottom: 10,
  },
  [`.${typographyClasses.body1}`]: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '1.8rem',
    color: palette.text.secondary,
    marginBottom: 30,
  },
}));

const FeedbackReceived: FC<FeedbackReceivedProps> = ({ handleCloseModal }) => {
  const { t } = useTranslation();

  return (
    <FeedbackReceivedContainer>
      <Logo />
      <Typography variant="h2">{t('feedbackIssueReceived')}</Typography>
      <Typography variant="body1">{t('feedbackIssueThankYou')}</Typography>
      <CloseButton
        variant="contained"
        onClick={handleCloseModal}
        aria-label="down"
      >
        {t('close')}
      </CloseButton>
    </FeedbackReceivedContainer>
  );
};

export default FeedbackReceived;
