import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, styled, svgIconClasses } from '@mui/material';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  padding: '4px 15px',
  height: 30,

  [`& .${svgIconClasses.root}`]: {
    padding: '0 5px 0 0',
    fontSize: '2.2rem',
  },
}));

const ExportView = () => {
  const { exportToExcel } = useTable();
  const { t } = useTranslation();

  return (
    <StyledButton onClick={exportToExcel} variant="outlined">
      <FileDownloadIcon fontSize="small" />
      {t('exportView')}
    </StyledButton>
  );
};

export default ExportView;
