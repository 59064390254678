import { typographyClasses } from '@mui/material';
import { styled } from '@mui/system';
import Autocomplete from 'components/common/Autocomplete';
import { Collapsable } from 'components/common/Table/Cell';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnName } from 'types/tableConfig';

import { SubHeader } from '../CommonStyles';
import { ContentColumnsProps } from './models';

const List = styled('ul')(({ theme }) => ({
  padding: 0,
  listStyle: 'none',

  li: {
    margin: '20px 0',
    fontSize: '1.2rem',
    color: theme.palette.text.primary,
  },

  [`.${typographyClasses.h3}`]: {
    marginBottom: 5,
  },
}));

const ContentColumns: FC<ContentColumnsProps> = ({
  columns,
  onAssignedChange,
  assignActive,
}) => {
  const { t } = useTranslation();

  const renderField = (
    columnName: ColumnName,
    CustomRenderer: FC<any> | undefined,
    value?: string
  ) => {
    if (columnName === 'assigned') {
      return (
        <Autocomplete
          value={value}
          onValueChange={onAssignedChange}
          isFocused={assignActive}
        />
      );
    }

    return CustomRenderer && CustomRenderer !== Collapsable ? (
      <CustomRenderer visible value={value} />
    ) : (
      value
    );
  };

  return (
    <List>
      {columns.map(({ columnName, customRenderer: CustomRenderer, value }) => (
        <li key={columnName}>
          <SubHeader variant="h3">{t(columnName)}</SubHeader>
          {renderField(columnName, CustomRenderer, value as string)}
        </li>
      ))}
    </List>
  );
};

export default ContentColumns;
