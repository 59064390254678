import { BaseApiResponse } from 'types/api';

export const handleApiRequest = async <T extends BaseApiResponse>(
  path: string,
  method: string,
  body?: any
): Promise<T | BaseApiResponse> => {
  try {
    const reviewResult = await fetch(
      `${process.env.REACT_APP_API_URL}${path}`,
      {
        ...(body ? { body: JSON.stringify(body) } : {}),
        method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (reviewResult.ok) {
      return { ...(await reviewResult.json()), success: true };
    }

    return { success: false, message: reviewResult.statusText };
  } catch (error) {
    return { success: false, message: error };
  }
};
