import { DEFAULT_TABLE_CONFIG } from 'constants/table';
import { getDefaultSort } from 'helpers/defaultSorting';
import useIndiceStats from 'hooks/useIndiceStats';
import { cloneDeep } from 'lodash';
import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { AlgoliaHit } from 'types/algolia';
import { TableConfig } from 'types/tableConfig';

import { Sorting, TableContextProviderProps, TableContextType } from './models';

export const defaultValue: TableContextType = {
  columns: DEFAULT_TABLE_CONFIG,
  localStates: {},
  sorting: getDefaultSort(),
  loadedHits: [],
  currentPage: 1,
  stats: {
    totalEntries: 0,
    lastUpdateDatetime: new Date(),
  },
  loading: true,
  setCurrentPage: () => {
    throw new Error('setCurrentPage not implemented');
  },
  setSorting: () => {
    throw new Error('setSorting not implemented');
  },
  updateColumns: () => {
    throw new Error('updateColumns not implemented');
  },
  updateLocalState: () => {
    throw new Error('updateLocalState not implemented');
  },
  setLoading: () => {
    throw new Error('setLoading not implemented');
  },
  updateLoadedHits: () => {
    throw new Error('updateLoadedHits not implemented');
  },
};

export const TableContext = createContext<TableContextType>(defaultValue);

const TableContextProvider: FC<TableContextProviderProps> = ({
  children,
  config,
}) => {
  const [state, setState] = useState<TableContextType>(defaultValue);
  const { getStats } = useIndiceStats();

  useEffect(() => {
    const getInitialData = async () => {
      const stats = await getStats();

      setState((currState) => ({
        ...currState,
        ...(stats ? { stats: { ...currState.stats, ...stats } } : {}),
      }));
    };

    getInitialData();
  }, [getStats]);

  const contextValue = useMemo(() => {
    const setSorting = (sorting: Sorting) => {
      setState((currState) => ({ ...currState, sorting }));
    };

    const setCurrentPage = (currentPage: number) => {
      setState((currState) => ({ ...currState, currentPage }));
    };

    const updateColumns = (columns: TableConfig) => {
      setState((currState) => ({
        ...currState,
        columns: { ...currState.columns, ...columns },
      }));
    };

    const setLoading = (loading: boolean) => {
      setState((currState) => ({
        ...currState,
        loading,
      }));
    };

    const updateLoadedHits = (hits: AlgoliaHit[]) => {
      setState((currState) => {
        const loadedHits = cloneDeep(hits);

        return {
          ...currState,
          loadedHits,
        };
      });
    };

    const updateLocalState = (
      objectId: string,
      states: Record<string, any>
    ) => {
      setState((currState) => ({
        ...currState,
        localStates: {
          ...currState.localStates,
          [objectId]: {
            ...(currState.localStates[objectId]
              ? currState.localStates[objectId]
              : {}),
            ...states,
          },
        },
      }));
    };

    return {
      ...state,
      setSorting,
      setCurrentPage,
      updateColumns,
      updateLocalState,
      setLoading,
      updateLoadedHits,
    };
  }, [state]);

  return (
    <TableContext.Provider value={config || contextValue}>
      {children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
