import useAuth from 'hooks/useAuth';
import { createContext, FC, useEffect, useMemo, useState } from 'react';

import { AuthContextProviderProps, AuthContextType } from './models';

export const defaultValue: AuthContextType = {
  users: [],
  currentUser: null,
};

export const AuthContext = createContext<AuthContextType>(defaultValue);

const AuthContextProvider: FC<AuthContextProviderProps> = ({
  children,
  config,
}) => {
  const [state, setState] = useState<AuthContextType>(defaultValue);
  const { getUserInfo, getAppDetails } = useAuth();

  useEffect(() => {
    const getInitialData = async () => {
      const appDetails = await getAppDetails();
      const user = await getUserInfo();

      setState((currState) => ({
        ...currState,
        ...(appDetails?.data
          ? { users: [...appDetails.data.app_details.users_list] }
          : {}),
        ...(user ? { currentUser: { ...user } } : {}),
      }));
    };

    getInitialData();
  }, [getAppDetails, getUserInfo]);

  const contextValue = useMemo(() => state, [state]);

  return (
    <AuthContext.Provider value={config || contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
