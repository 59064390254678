import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connectMenu } from 'react-instantsearch-dom';

import { SelectListProps } from './models';

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '35px',
  color: theme.palette.info.main,
  opacity: 0.7,
  fontSize: '1.2rem',
  svg: {
    color: theme.palette.secondary.main,
  },
}));

const StyledDefaultMenuItem = styled(MenuItem)(({ theme }) => ({
  opacity: 1,
  color: theme.palette.info.main,
}));

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '1.2rem',
  color: theme.palette.info.dark,
  marginBottom: '5px',
}));

const SelectWrapper = styled('div')(() => ({
  padding: '0 5px',
  alignItems: 'center',
  display: 'flex',
}));

const SelectList: FC<SelectListProps> = ({
  items,
  currentRefinement,
  refine,
  selectLabel,
}) => {
  const { t } = useTranslation();

  return (
    <SelectWrapper>
      <div>
        <StyledLabel variant="standard" id="select">
          {selectLabel}
        </StyledLabel>
        <FormControl sx={{ minWidth: 115, height: 35 }}>
          <StyledSelect
            labelId="select"
            displayEmpty
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              refine(target.value);
            }}
            value={currentRefinement || ''}
          >
            <StyledDefaultMenuItem value="">
              {t('seeAll')}
            </StyledDefaultMenuItem>
            {items.map(({ label, isRefined, value }) => (
              <MenuItem
                key={label}
                value={isRefined ? currentRefinement : value}
              >
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </div>
    </SelectWrapper>
  );
};

export default connectMenu(SelectList);
