/* eslint-disable camelcase */
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import classNames from 'classnames';
import ToolTip from 'components/common/Tooltip';
import { convertTimestamp } from 'helpers/dateConverting';
import { FC } from 'react';

import { PredictionProps } from './models';

const StyledParagraph = styled('p')(() => ({
  margin: '3px 0',

  '&.empty-paragraph': {
    textAlign: 'center',
  },
}));

const Span = styled('span')(({ theme }) => ({
  fontWeight: 'bold',

  '&.NotAdverseEvent': {
    color: theme.palette.success.main,
  },

  '&.overrided': {
    textDecoration: 'line-through' /* !IMP line through check how it works */,
    display: 'block',
  },

  '&.serious': {
    color: theme.palette.success.light,
  },

  '&.notserious': {
    color: theme.palette.success.dark,
  },
}));

const parseValidationHistory = (value) => {
  const parsedValue = JSON.parse(value);
  const validationHistory = Object.keys(parsedValue).map((key) => [
    key,
    parsedValue[key],
  ]);

  const validationHistoryItems = validationHistory.map((elem) => (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledParagraph>{elem[1]?.validation_l_1}</StyledParagraph>
      </Grid>
      <Grid item xs={4}>
        <StyledParagraph
          className={classNames({
            'empty-paragraph': !elem[1]?.assigned_l_1,
          })}
        >
          {elem[1]?.assigned_l_1 ? elem[1].assigned_l_1 : <span>-</span>}
        </StyledParagraph>
      </Grid>
      <Grid item xs={4}>
        <StyledParagraph>
          {convertTimestamp(elem[1]?.date_created_validation_l_1)}
        </StyledParagraph>
      </Grid>
    </Grid>
  ));

  return <div>{validationHistoryItems}</div>;
};

export const Prediction: FC<PredictionProps> = ({
  value: [prediction_l_1, validation_l_1, prediction_l_2, validation_history],
}) => {
  const overrided = validation_l_1 && validation_l_1 !== prediction_l_1;

  return (
    <>
      <Span
        className={classNames(prediction_l_1.replace(/\s/g, ''), {
          overrided,
        })}
      >
        {prediction_l_1}
        {validation_history ? (
          <ToolTip
            type="info"
            tooltipText={parseValidationHistory(validation_history)}
          />
        ) : null}
      </Span>
      {prediction_l_2 ? (
        <StyledParagraph>
          <Span className={prediction_l_2.replace(/\s/g, '')}>
            {prediction_l_2}
          </Span>
        </StyledParagraph>
      ) : null}
      {overrided ? (
        <Span className={validation_l_1.replace(/\s/g, '')}>
          {validation_l_1}
        </Span>
      ) : null}
    </>
  );
};
