import {
  RateReviewOutlined,
  ThumbDownOffAlt,
  ThumbUpOffAlt,
} from '@mui/icons-material';
import { Button, buttonClasses, IconButton, styled } from '@mui/material';
import useReview from 'hooks/useReview';
import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionType } from 'types/tableConfig';

import { ActionsProps } from './models';

const IconButtonStyled = styled(IconButton)(
  ({ theme: { palette }, color }) => ({
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 5,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',

    '&.active': {
      backgroundColor: color ? palette[color].main : '',

      svg: {
        color: palette.common.white,
      },
    },

    svg: {
      fontSize: '1.6rem',
    },
  })
);

const ButtonStyled = styled(Button)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.primary.main}`,
  color: palette.text.primary,
  textTransform: 'none',
  marginRight: 10,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  fontSize: '1rem',
  fontWeight: 'bold',

  [`.${buttonClasses.startIcon} > svg`]: {
    fontSize: '1.3rem',
    color: palette.secondary.dark,
  },
}));

export const Actions: FC<ActionsProps> = ({
  value,
  reviewRaw: { objectID, id: reviewId },
  reviewRaw,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setReviewCorrect } = useReview();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { assignedToCurrentUserIfNotExists } = useReview();

  const onThumbUpClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setButtonsDisabled(true);
    await setReviewCorrect(
      objectID,
      reviewId,
      assignedToCurrentUserIfNotExists(reviewRaw)
    );
    setButtonsDisabled(false);
  };

  const onThumbDownClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`/review/${objectID}`);
  };

  const handleAssignReview = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`/review/${objectID}/assign`);
  };

  return (
    <>
      <ButtonStyled
        color="info"
        variant="outlined"
        startIcon={<RateReviewOutlined />}
        aria-label={t('assign')}
        onClick={handleAssignReview}
        disabled={buttonsDisabled}
      >
        {t('assign')}
      </ButtonStyled>
      <IconButtonStyled
        color="success"
        className={value === ActionType.CORRECT ? 'active' : ''}
        onClick={onThumbUpClick}
        aria-label="up"
        style={{ marginRight: 10 }}
        disabled={buttonsDisabled}
      >
        <ThumbUpOffAlt />
      </IconButtonStyled>
      <IconButtonStyled
        color="warning"
        className={value === ActionType.INCORRECT ? 'active' : ''}
        onClick={onThumbDownClick}
        aria-label="down"
        disabled={buttonsDisabled}
      >
        <ThumbDownOffAlt />
      </IconButtonStyled>
    </>
  );
};
