import { ThemeContext } from 'contexts/ThemeContext';
import { debounce } from 'lodash';
import { useContext, useLayoutEffect, useRef } from 'react';

const useHeaderSizeHandler = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    const updateSize = debounce(() => {
      if (!headerRef.current) {
        return;
      }
      setTheme({
        sizes: { headerHeight: 106 + headerRef.current.offsetHeight },
      });
    }, 100);
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { headerRef };
};

export default useHeaderSizeHandler;
