import { ThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createContext, FC, useMemo, useState } from 'react';
import defaultTheme from 'themes/theme';

import { Theme, ThemeContextProviderProps, ThemeContextType } from './models';

export const defaultValue: ThemeContextType = {
  theme: defaultTheme,
  setTheme: () => {
    throw new Error('setTheme not implemented');
  },
};

export const ThemeContext = createContext<ThemeContextType>(defaultValue);

const ThemeContextProvider: FC<ThemeContextProviderProps> = ({
  children,
  config,
}) => {
  const [state, setState] = useState<ThemeContextType>(defaultValue);

  const contextValue = useMemo(() => {
    const setTheme = (updated: Partial<Theme>) => {
      setState((s) => ({ ...s, theme: { ...s.theme, ...updated } }));
    };

    return {
      ...state,
      setTheme,
    };
  }, [state]);

  return (
    <ThemeContext.Provider value={config || contextValue}>
      <MuiThemeProvider<Theme> theme={state.theme}>
        <ThemeProvider theme={state.theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
