import { styled } from '@mui/system';
import SearchInput from 'components/common/SearchInput';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -10px 15px',
}));

export const TableFilters = () => {
  const { prepareFiltersToDisplay } = useTable();
  const filters = prepareFiltersToDisplay();
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <SearchInput />
      {filters.map(({ name, Component, items }) => (
        <Component
          key={name}
          attribute={name}
          selectLabel={t(name)}
          items={items}
        />
      ))}
    </StyledContainer>
  );
};

export default TableFilters;
