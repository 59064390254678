import { styled } from '@mui/material';
import MuiRating from '@mui/material/Rating';
import { FC, useMemo } from 'react';

import { RatingProps } from './models';

const RatingStyled = styled(MuiRating)(() => ({
  fontSize: '1.6rem',
}));

export const Rating: FC<RatingProps> = ({ value }) =>
  useMemo(
    () => <RatingStyled name="read-only" value={value} readOnly />,
    [value]
  );
