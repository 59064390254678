const RATING_ITEMS_CONFIG = [
  { label: 'all' },
  { end: 1, label: '1' },
  { start: 2, end: 2, label: '2' },
  { start: 3, end: 3, label: '3' },
  { start: 4, end: 4, label: '4' },
  { start: 5, end: 5, label: '5' },
];

export default RATING_ITEMS_CONFIG;
