import { iconClasses, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { convertDateToTimestamp } from 'helpers/dateConverting';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectRange } from 'react-instantsearch-dom';

import { DatesFilterProps } from './models';

const StyledContainer = styled('div')(() => ({
  display: 'flex',

  '.MuiInputBase-root': {
    width: '140px',
    height: '35px',
    margin: '0 5px',
    fontSize: '1.2rem',
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const StyledDateRange = styled('div')(({ theme }) => ({
  '.MuiSvgIcon-root': {
    color: theme.palette.secondary.main,
  },

  [`.${iconClasses.root}`]: {
    color: theme.palette.secondary.main,
  },

  fieldset: {
    height: '35px',
    top: '0',
  },

  legend: {
    display: 'none',
  },
}));

const StyledLabel = styled('span')(() => ({
  fontSize: '1.2rem',
  margin: '0 0 2px 10px',
}));

export const DatesFilter: FC<DatesFilterProps> = ({
  refine,
  currentRefinement,
}) => {
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!currentRefinement.min && !currentRefinement.max) {
      setDateStart(null);
      setDateEnd(null);
    }
  }, [currentRefinement]);

  useEffect(() => {
    if (dateStart && dateEnd) {
      refine({
        min: convertDateToTimestamp(dateStart, true),
        max: convertDateToTimestamp(dateEnd),
      });
    }
  }, [dateStart, dateEnd, refine]);

  return (
    <StyledContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledDateRange>
          <StyledLabel>{t('dateFrom')}</StyledLabel>
          <DatePicker
            value={dateStart}
            onChange={setDateStart}
            maxDate={new Date()}
            renderInput={(params) => <TextField {...params} />}
          />
        </StyledDateRange>
        <StyledDateRange>
          <StyledLabel>{t('dateTo')}</StyledLabel>
          <DatePicker
            value={dateEnd}
            onChange={setDateEnd}
            disabled={!dateStart}
            minDate={dateStart || undefined}
            maxDate={new Date()}
            renderInput={(params) => <TextField {...params} />}
          />
        </StyledDateRange>
      </LocalizationProvider>
    </StyledContainer>
  );
};

export default connectRange(DatesFilter);
