import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Header from 'components/common/Header';
import { FC } from 'react';

const StyledContainer = styled(Container)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  padding: '0 15px !important',
  background: theme.palette.background.default,

  header: {
    height: (theme as any).sizes.headerHeight,
  },
}));

export const Layout: FC = ({ children }) => (
  <StyledContainer maxWidth={false}>
    <Header />
    {children}
  </StyledContainer>
);
