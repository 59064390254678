import { Alert, Box, CircularProgress, Modal, styled } from '@mui/material';
import { handleError } from 'helpers/errorHandler';
import useReview from 'hooks/useReview';
import useTable from 'hooks/useTable';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Review } from 'types/algolia';

import EscalateReview from './EscalateReview';
import FeedbackReceived from './FeedbackReceived';
import { ReviewModalProps } from './models';
import ReviewDefaultView from './ReviewDefaultView';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '30%',
  minWidth: 660,
  borderRadius: 5,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: 35,
  outline: 0,
}));

const LoaderContainer = styled('div')(() => ({
  textAlign: 'center',
}));

const ReviewModal: FC<ReviewModalProps> = ({ assignActive }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { prepareRowToDisplayInModal } = useTable();
  const [feedbackIssueReceived, setFeedbackIssueReceived] = useState(false);
  const [reviewRaw, setReviewRaw] = useState<Review | null>(null);
  const [escalateReview, setEscalateReview] = useState(false);
  const {
    getReview,
    reviewIssue,
    assignUsers,
    assignedToCurrentUserIfNotExists,
  } = useReview();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const initialize = async () => {
      if (!id) {
        return;
      }

      try {
        setReviewRaw(await getReview(id));
        setLoading(false);
      } catch (error) {
        handleError(error);
        setLoading(false);
        setErrorMessage(t('anErrorOccuredReview'));
      }
    };

    initialize();
  }, [getReview, id, t]);

  const review = useMemo(
    () => (reviewRaw ? prepareRowToDisplayInModal(reviewRaw) : null),
    [prepareRowToDisplayInModal, reviewRaw]
  );

  const handleCloseModal = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const onAssignedChange = useCallback(
    async (assignedTo: string[]) => {
      if (!reviewRaw) {
        return false;
      }
      const assignedToString = assignedTo.join(', ');

      return assignUsers(reviewRaw.objectID, reviewRaw.id, assignedToString);
    },
    [assignUsers, reviewRaw]
  );

  const handleEscalateReview = () => {
    setEscalateReview(true);
  };

  const handleReviewIssue = async (reason: string) => {
    if (!reviewRaw) {
      return false;
    }
    const result = await reviewIssue(
      reviewRaw.objectID,
      reviewRaw.id,
      assignedToCurrentUserIfNotExists(reviewRaw),
      reason
    );
    if (result) {
      setFeedbackIssueReceived(true);
    }

    return result;
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <StyledBox>
        {errorMessage.length ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : null}

        {loading ? (
          <LoaderContainer>
            <CircularProgress size={80} color="secondary" />
          </LoaderContainer>
        ) : null}
        {review ? (
          <>
            {!feedbackIssueReceived && !escalateReview ? (
              <ReviewDefaultView
                assignActive={assignActive}
                review={review}
                reviewRaw={reviewRaw}
                onAssignedChange={onAssignedChange}
                handleCloseModal={handleCloseModal}
                handleEscalateReview={handleEscalateReview}
                handleReviewIssue={handleReviewIssue}
              />
            ) : null}
            {feedbackIssueReceived ? (
              <FeedbackReceived handleCloseModal={handleCloseModal} />
            ) : null}
            {escalateReview ? (
              <EscalateReview
                reviewRaw={reviewRaw}
                handleCloseModal={handleCloseModal}
                handleCancelButton={() => setEscalateReview(false)}
              />
            ) : null}
          </>
        ) : null}
      </StyledBox>
    </Modal>
  );
};

export default ReviewModal;
