import { styled } from '@mui/material';
import algoliasearch from 'algoliasearch';
import AlgoliaPagination from 'components/AlgoliaPagination';
import AlgoliaSort from 'components/AlgoliaSort';
import AlgoliaTable from 'components/AlgoliaTable';
import { Layout } from 'components/common/Layout';
import ReviewModal from 'components/ReviewModal';
import TableConfig from 'components/TableConfig';
import TableFilters from 'components/TableFilters';
import useHeaderSizeHandler from 'hooks/useHeaderSizeHandler';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { Route, Routes } from 'react-router-dom';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
);

const SubHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: '10px 0',
}));

export const Home = () => {
  const { headerRef } = useHeaderSizeHandler();

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_INDEX as string}
      >
        <Layout>
          <div ref={headerRef}>
            <TableConfig />
            <SubHeader>
              <TableFilters />
              <AlgoliaPagination />
            </SubHeader>
          </div>
          <AlgoliaSort />
          <Configure hitsPerPage={30} />
          <AlgoliaTable />
        </Layout>
      </InstantSearch>
      <Routes>
        <Route
          path="/review/:id/assign"
          element={<ReviewModal assignActive />}
        />
        <Route path="/review/:id" element={<ReviewModal />} />
      </Routes>
    </>
  );
};
