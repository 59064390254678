import { styled } from '@mui/material';
import { FC, useMemo } from 'react';
import { ModalColumnPosition } from 'types/tableConfig';

import Actions from '../Actions';
import ContentColumns from '../ContentColumns';
import Header from '../Header';
import HeaderColumns from '../HeaderColumns';
import { ReviewDefaultViewProps } from './models';

const Spacer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 2,
}));

const ReviewDefaultView: FC<ReviewDefaultViewProps> = ({
  review,
  reviewRaw,
  onAssignedChange,
  handleCloseModal,
  handleEscalateReview,
  handleReviewIssue,
  assignActive,
}) => {
  const headerColumns = useMemo(
    () =>
      review?.filter(
        ({ modal }) => modal?.position === ModalColumnPosition.TOP
      ),
    [review]
  );

  const bottomColumns = useMemo(
    () =>
      review?.filter(
        ({ modal }) => modal?.position === ModalColumnPosition.BOTTOM
      ),
    [review]
  );

  const Scroll = styled('div')(() => ({
    overflowY: 'auto',
    maxHeight: 'calc(100vh - (20vh) - 200px)',
  }));

  return (
    <>
      <Scroll>
        <Header reviewRaw={reviewRaw} />
        {headerColumns ? <HeaderColumns columns={headerColumns} /> : null}
        <Spacer />
        {bottomColumns ? (
          <ContentColumns
            columns={bottomColumns}
            onAssignedChange={onAssignedChange}
            assignActive={assignActive}
          />
        ) : null}
      </Scroll>
      <Spacer />
      <Actions
        action={review?.find(({ columnName }) => columnName === 'Actions')}
        reviewRaw={reviewRaw}
        handleCloseButton={handleCloseModal}
        handleEscalateReview={handleEscalateReview}
        handleReviewIssue={handleReviewIssue}
      />
    </>
  );
};

export default ReviewDefaultView;
