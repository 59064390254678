import {
  Autocomplete as MuiAutocomplete,
  autocompleteClasses,
  Button,
  inputClasses,
  styled,
  svgIconClasses,
  TextField,
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import useIsElementVisible from 'hooks/useIsElementVisible';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteProps } from './models';

const StyledTextField = styled(TextField)(
  ({ theme: { palette, customColors } }: any) => ({
    border: `1px solid ${palette.primary.main}`,
    borderRadius: 5,
    paddingRight: 100,

    [`.${inputClasses.root}::before, .${inputClasses.root}::after`]: {
      display: 'none',
    },

    [`.${autocompleteClasses.tag}`]: {
      backgroundColor: customColors.tag,
      borderRadius: 5,
      fontSize: '1rem',
      height: 23,

      [`.${svgIconClasses.root}`]: {
        fontSize: '1.6rem',
      },
    },

    [`.${autocompleteClasses.endAdornment}`]: {
      display: 'none',
    },
  })
);

const AutocompleteContainer = styled('div')(() => ({
  position: 'relative',
}));

const AssignButton = styled(Button)(({ theme: { palette } }) => ({
  position: 'absolute',
  right: 4,
  top: 4,
  fontSize: '1rem',
  textTransform: 'none',
  padding: '4px 16px',
  borderRadius: 5,

  '&::hover': {
    backgroundColor: palette.secondary.main,
  },
}));

const Autocomplete: FC<AutocompleteProps> = ({
  value,
  onValueChange,
  isFocused,
}) => {
  const [inputDisabled, setInputDisabled] = useState(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const defaultValue =
    value
      ?.split(',')
      .map((val) => val.trim())
      .filter((val) => val?.length) || [];
  const [autocompleteValue, setAutocompleteValue] = useState(defaultValue);
  const previousValue = useRef(defaultValue);
  const { users, currentUser } = useAuth();
  const { t } = useTranslation();
  const assignToMeVisible = useMemo(
    () =>
      currentUser?.email
        ? !autocompleteValue.includes(currentUser.email)
        : false,
    [currentUser?.email, autocompleteValue]
  );

  const { isVisible, sentiel } = useIsElementVisible<HTMLDivElement>();

  useEffect(() => {
    const input = inputRef.current?.querySelector('input');
    if (!isVisible && input) {
      input.blur();
    }
  }, [isVisible, inputRef, isFocused]);

  useEffect(() => {
    const input = inputRef.current?.querySelector('input');
    if (isFocused && input) {
      input.focus();
    }
  }, [inputRef, isFocused]);

  const onChange = async (val: string[]) => {
    setInputDisabled(true);
    setAutocompleteValue(val);
    const result = await onValueChange(val);
    if (result) {
      previousValue.current = val;
    } else {
      setAutocompleteValue(previousValue.current);
    }
    setInputDisabled(false);
  };

  const onAssignToMeClick = () => {
    if (!currentUser?.email) {
      return;
    }
    const newUsersList = [...autocompleteValue, currentUser.email];
    onChange(newUsersList);
  };

  return (
    <AutocompleteContainer ref={sentiel}>
      <MuiAutocomplete
        multiple
        openOnFocus
        options={users.map(({ user_id: userId }) => userId)}
        getOptionLabel={(option) => option}
        value={autocompleteValue}
        filterSelectedOptions
        onChange={(_event, val) => onChange(val)}
        disabled={inputDisabled}
        renderInput={(params) => (
          <StyledTextField {...params} variant="standard" ref={inputRef} />
        )}
      />
      {assignToMeVisible ? (
        <AssignButton
          variant="contained"
          color="secondary"
          onClick={onAssignToMeClick}
        >
          {t('assignToMe')}
        </AssignButton>
      ) : null}
    </AutocompleteContainer>
  );
};

export default Autocomplete;
