import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolTipProps } from './models';

const StyledHelpIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
  cursor: 'pointer',
  fontSize: '1.7rem',
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  fontSize: '1.7rem',
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '0 5px',
  marginTop: '-5px',
}));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 400,
  },
});

const ToolTip: FC<ToolTipProps> = ({ tooltipText, type }) => {
  const { t } = useTranslation();

  return (
    <CustomWidthTooltip
      title={type === 'info' ? tooltipText : t(tooltipText as any)}
      arrow
    >
      <StyledIconButton size="small">
        {type === 'info' ? <StyledInfoIcon /> : <StyledHelpIcon />}
      </StyledIconButton>
    </CustomWidthTooltip>
  );
};

export default ToolTip;
