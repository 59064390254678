import useTable from 'hooks/useTable';
import { FC, useEffect } from 'react';
import { connectSortBy } from 'react-instantsearch-dom';

import { SortProps } from './models';

const Sort: FC<SortProps> = ({ sortingIndice, refine }) => {
  useEffect(() => {
    refine(sortingIndice);
  }, [sortingIndice, refine]);

  return null;
};

const AlgoliaSort = connectSortBy(Sort);

const ExportedAlgoliaSort: FC = () => {
  const { sorting } = useTable();

  return sorting ? (
    <AlgoliaSort items={[]} sortingIndice={sorting.indice} />
  ) : null;
};

export default ExportedAlgoliaSort;
