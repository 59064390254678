import { styled, Typography, typographyClasses } from '@mui/material';
import useReview from 'hooks/useReview';
import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStyled, CloseButton } from '../CommonStyles';
import { EscalateReviewProps } from './models';

const EscalateIssueContainer = styled('form')(({ theme: { palette } }) => ({
  [`.${typographyClasses.h6}`]: {
    fontSize: '1.2rem',
    color: palette.text.secondary,
    fontWeight: 'bold',
    lineHeight: '1.6rem',
  },

  textarea: {
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    height: 100,
    minHeight: 100,
    maxHeight: 200,
    outline: 0,
    borderColor: palette.primary.main,
    borderRadius: 5,
  },
}));

const Buttons = styled('div')(() => ({
  textAlign: 'right',
}));

const EscalateReview: FC<EscalateReviewProps> = ({
  handleCancelButton,
  handleCloseModal,
  reviewRaw,
}) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  const { escalateReview } = useReview();
  const [disabledButtons, setDisabledButtons] = useState(false);

  const onEscalateReviewClick = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!reviewRaw) {
      return;
    }
    setDisabledButtons(true);
    const result = await escalateReview(
      reviewRaw.objectID,
      reviewRaw.id,
      description
    );
    if (result) {
      handleCloseModal();
    }
    setDisabledButtons(false);
  };

  return (
    <EscalateIssueContainer onSubmit={onEscalateReviewClick}>
      <Typography variant="h6">{t('whatsIssue')}</Typography>
      <textarea
        placeholder={t('describeIssue')}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <Buttons>
        <ButtonStyled
          color="info"
          variant="outlined"
          type="submit"
          disabled={disabledButtons}
          aria-label={t('escalateReview')}
        >
          {t('escalateReview')}
        </ButtonStyled>
        <CloseButton
          variant="contained"
          onClick={handleCancelButton}
          aria-label="down"
          disabled={disabledButtons}
        >
          {t('cancel')}
        </CloseButton>
      </Buttons>
    </EscalateIssueContainer>
  );
};

export default EscalateReview;
