import { Review } from './algolia';

export type TableConfig = Record<ColumnName, ColumnConfig>;

export type ColumnName =
  | 'brand'
  | 'category'
  | 'market'
  | 'online_store'
  | 'original_review'
  | 'prediction_l_1'
  | 'product_description'
  | 'retailer_product_code'
  | 'review'
  | 'review_rating'
  | 'url'
  | 'source'
  | 'probability'
  | 'review_title'
  | 'assigned'
  | 'review_timestamp'
  | 'Actions';

export interface ColumnConfig {
  visible: boolean;
  customRenderer?: React.FC<any>;
  order: number;
  valueFn?: (row: Review) => string | number | ActionType | string[];
  customClasses?: string;
  width?: string;
  defaultSortDirection?: SortOrder;
  sortReplicaIndice?: string;
  filterComponent?: React.ComponentType<any>;
  modal?: ModalColumn;
  filter?: {
    component: any;
    order: number;
    items?: any;
  };
  tooltipTranslationKey?: string;
}

export interface ModalColumn {
  order: number;
  position: ModalColumnPosition;
}

export enum ModalColumnPosition {
  TOP,
  BOTTOM,
  NONE,
}

export enum SortOrder {
  ASC,
  DESC,
}

export enum ActionType {
  CORRECT,
  INCORRECT,
  NONE,
}
