import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import useTable from 'hooks/useTable';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnName } from 'types/tableConfig';

import { ColumnsSwitcherProps } from './models';

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.dark,
  textTransform: 'none',
  fontSize: '1rem',
  height: 30,
  background: theme.palette.background.paper,

  '&.active': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const ColumnsSwitcher: FC<ColumnsSwitcherProps> = ({ columns }) => {
  const { t } = useTranslation();
  const { showHideColumns, getAllColumns } = useTable();
  const activeColumn = useMemo(
    () =>
      getAllColumns().find(
        ({ visible, columnName }) => columns.includes(columnName) && visible
      )?.columnName,
    [columns, getAllColumns]
  );

  const onSetActiveColumnClick = (column: ColumnName) => {
    showHideColumns(
      [column],
      columns.filter((col) => col !== column)
    );
  };

  return (
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
      {columns.map((column) => (
        <StyledButton
          key={column}
          className={activeColumn === column ? 'active' : ''}
          onClick={() => onSetActiveColumnClick(column)}
        >
          {t(`${column}Switch`)}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
};

export default ColumnsSwitcher;
