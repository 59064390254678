import { InputLabel, inputLabelClasses, Rating, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectNumericMenu } from 'react-instantsearch-dom';

import ClearFilters from '../ClearFilters';
import { RatingsFiltersProps } from './models';

const StyledRatingFilter = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  padding: '0 5px',

  [`.${inputLabelClasses.root}`]: {
    fontSize: '1.2rem',
    color: theme.palette.info.dark,
    marginBottom: '5px',
  },
}));

export const RatingsFilter: FC<RatingsFiltersProps> = ({
  items,
  refine,
  currentRefinement,
}) => {
  const [ratingValue, setRatingValue] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (!currentRefinement) {
      setRatingValue(0);
    }
  }, [currentRefinement]);

  const handleRateChange = (eventValue: number) => {
    if (eventValue) {
      setRatingValue(eventValue);
      refine(items[eventValue].value);
    }
  };

  return (
    <StyledRatingFilter spacing={1}>
      <InputLabel>{t('ReviewRating')}</InputLabel>
      <div>
        <Rating
          onClick={(event: MouseEvent) => {
            const target = event.target as HTMLButtonElement;
            handleRateChange(+target.value);
          }}
          name="rating"
          defaultValue={0}
          precision={1}
          value={ratingValue}
        />
        <ClearFilters />
      </div>
    </StyledRatingFilter>
  );
};

export default connectNumericMenu(RatingsFilter);
