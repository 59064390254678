import { CheckBoxOutlined } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  Checkbox,
  checkboxClasses,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
  typographyClasses,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import { COLUMNS_CONFIG } from 'constants/localStorage';
import useLocalStorage from 'hooks/useLocalStorage';
import useTable from 'hooks/useTable';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnName } from 'types/tableConfig';

import { ColumnsConfig } from './models';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  borderColor: theme.palette.primary.dark,
  padding: '4px 15px',
  height: 30,
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 2,
  boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.12)',
  border: `1px solid ${theme.palette.primary.main}`,
  background: 'white',
  padding: 25,
  borderRadius: '5px 0px 0px 5px',
  marginTop: '10px !important',
  width: 240,

  [`.${typographyClasses.h6}`]: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  [`.${typographyClasses.subtitle1}`]: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
}));

const Arrow = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'absolute',
  width: '2.1rem',
  height: '1.5em',
  boxSizing: 'border-box',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '-1.5rem',
  marginLeft: 4,
  marginRight: 4,

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'currentColor',
    transform: 'rotate(45deg)',
    transformOrigin: '0 100%',
    color: theme.palette.common.white,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`.${typographyClasses.root}`]: {
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1.8rem',
  },
  [`.${checkboxClasses.root}`]: {
    color: theme.palette.primary.main,
  },
  [`.${checkboxClasses.checked}`]: {
    color: `${theme.palette.secondary.main} !important`,
  },
}));

const ColumnsSelector: FC = () => {
  const { t } = useTranslation();
  const [configVisible, setConfigVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const { getAllColumns, showHideColumns } = useTable();
  const columns = useMemo(
    () => getAllColumns().filter(({ columnName }) => columnName !== 'Actions'),
    [getAllColumns]
  );
  const { getValue, setValue } = useLocalStorage();
  const [columnsConifgLoaded, setColumnsConifgLoaded] = useState(false);

  useEffect(() => {
    if (columnsConifgLoaded) {
      return;
    }

    const loadSavedColumnsConfig = () => {
      const columnsVisibility = getValue<ColumnsConfig[]>(COLUMNS_CONFIG);
      if (columnsVisibility) {
        const visibleColumns = columnsVisibility
          .filter(({ visible }) => visible)
          .map(({ name }) => name);
        const hiddenColumns = columnsVisibility
          .filter(({ visible }) => !visible)
          .map(({ name }) => name);

        showHideColumns(visibleColumns, hiddenColumns);
      }
    };

    loadSavedColumnsConfig();
    setColumnsConifgLoaded(true);
  }, [getValue, showHideColumns, columnsConifgLoaded]);

  useEffect(() => {
    const updateColumnsConfig = () => {
      setValue(
        COLUMNS_CONFIG,
        columns.map(
          ({ columnName: name, visible }) =>
            ({ name, visible } as ColumnsConfig)
        )
      );
    };

    updateColumnsConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const onCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (columns.filter(({ visible }) => visible).length <= 1 && !checked) {
      return;
    }
    const columnArray = [event.target.value] as ColumnName[];
    showHideColumns(checked ? columnArray : [], checked ? [] : columnArray);
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        onClick={() => setConfigVisible(!configVisible)}
        startIcon={<SettingsIcon />}
        ref={buttonRef}
      >
        {t('configureTable')}
      </StyledButton>
      <StyledPopper
        id="columns-selector"
        open={configVisible}
        anchorEl={buttonRef.current}
        placement="bottom"
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef.current,
            },
          },
        ]}
      >
        <Arrow ref={arrowRef} />
        <Typography variant="h6">{t('configureTable')}</Typography>
        <Typography variant="subtitle1">{t('addHideRows')}</Typography>

        <FormGroup>
          {columns.map(({ columnName, visible }) => (
            <StyledFormControlLabel
              key={columnName}
              control={
                <Checkbox
                  value={columnName}
                  size="small"
                  checked={visible}
                  checkedIcon={<CheckBoxOutlined />}
                  onChange={onCheckboxChange}
                />
              }
              label={t(columnName)}
            />
          ))}
        </FormGroup>
      </StyledPopper>
    </>
  );
};

export default ColumnsSelector;
