import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { FC, useMemo } from 'react';

import { CellProps } from './models';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  fontSize: '1rem',
  fontWeight: 'normal',
  color: theme.palette.text.primary,
  height: '91px',
  padding: '16px 19px',
  cursor: 'pointer',

  '&.sticky-right': {
    position: 'sticky',
    right: -1,
    background: 'inherit',
  },
}));

export const Cell: FC<CellProps> = ({
  value,
  customClasses,
  customRenderer: CustomRenderer,
  visible,
  reviewRaw,
}) =>
  useMemo(
    () => (
      <StyledTableCell className={customClasses}>
        {CustomRenderer ? (
          <CustomRenderer
            visible={visible}
            value={value}
            reviewRaw={reviewRaw}
          />
        ) : (
          value
        )}
      </StyledTableCell>
    ),
    [CustomRenderer, customClasses, value, visible, reviewRaw]
  );
