import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import { FC } from 'react';

import { Cell } from '../Cell';
import { RowProps } from './models';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.default,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Row: FC<RowProps> = ({ row, onClick, reviewRaw }) => (
  <StyledTableRow hover onClick={onClick}>
    {row.map(
      ({ columnName, value, customClasses, visible, customRenderer }) => (
        <Cell
          key={columnName}
          columnName={columnName}
          value={value}
          customRenderer={customRenderer}
          customClasses={classNames(customClasses, {
            hidden: !visible,
          })}
          visible={visible}
          reviewRaw={reviewRaw}
        />
      )
    )}
  </StyledTableRow>
);
