import { UseLocalStorage } from './models';

const useLocalStorage: UseLocalStorage = () => {
  const getValue = <T = string>(key: string, isJSON = true): T | null => {
    if (!localStorage) {
      return null;
    }

    const itemValue = localStorage.getItem(key);

    if (itemValue && isJSON) {
      try {
        return JSON.parse(itemValue) as T;
      } catch (e) {
        return null;
      }
    }

    return itemValue as any;
  };

  const setValue = (key: string, value: any, isJSON = true): boolean => {
    if (!localStorage) {
      return false;
    }

    if (isJSON) {
      try {
        localStorage.setItem(key, JSON.stringify(value));

        return true;
      } catch (e) {
        return false;
      }
    }
    localStorage.setItem(key, value);

    return true;
  };

  return {
    getValue,
    setValue,
  };
};

export default useLocalStorage;
