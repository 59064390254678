import { DEFAULT_TABLE_CONFIG } from 'constants/table';
import { SortOrder } from 'types/tableConfig';

export const getDefaultSort = () => {
  const defaultSort = Object.entries(DEFAULT_TABLE_CONFIG).find(
    ([, { defaultSortDirection }]) => defaultSortDirection
  );

  if (!defaultSort) {
    return undefined;
  }

  const [column, { sortReplicaIndice, defaultSortDirection }] =
    defaultSort || [];

  return {
    column,
    indice: buildIndiceName(sortReplicaIndice as string, defaultSortDirection),
    direction: defaultSortDirection as SortOrder,
  };
};

export const buildIndiceName = (
  sortReplicaIndice: string,
  direction: SortOrder = SortOrder.ASC
) => `${sortReplicaIndice}_${SortOrder[direction].toLowerCase()}`;
