import {
  ErrorOutline,
  ThumbDownOffAlt,
  ThumbUpOffAlt,
} from '@mui/icons-material';
import { styled, Typography, typographyClasses } from '@mui/material';
import useReview from 'hooks/useReview';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType } from 'types/tableConfig';

import { Buttons, ButtonStyled, CloseButton } from '../CommonStyles';
import { ActionsProps } from './models';
import ReviewIssue from './ReviewIssue';

const ActionsStyled = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  marginTop: 20,

  [`.${typographyClasses.body2}`]: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    marginTop: 5,
    fontSize: '1.2rem',
  },
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h3}`]: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const Actions: FC<ActionsProps> = ({
  handleCloseButton,
  handleEscalateReview,
  handleReviewIssue,
  reviewRaw,
  action,
}) => {
  const { t } = useTranslation();
  const [reviewIssueActive, setReviewIssueActive] = useState(false);
  const [feedbackReceived, setFeedbackReceived] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { setReviewCorrect, assignedToCurrentUserIfNotExists } = useReview();

  const onCorrectReviewClick = async () => {
    if (!reviewRaw) {
      return;
    }
    setButtonsDisabled(true);
    const result = await setReviewCorrect(
      reviewRaw.objectID,
      reviewRaw.id,
      assignedToCurrentUserIfNotExists(reviewRaw)
    );
    if (result) {
      setFeedbackReceived(true);
    }

    setButtonsDisabled(false);
  };

  return (
    <ActionsStyled>
      {!reviewIssueActive ? (
        <div>
          <SubHeader variant="h3">{t('Actions')}</SubHeader>
          <Typography
            variant="inherit"
            dangerouslySetInnerHTML={{ __html: t('actionsModalDescription') }}
          />
          <Buttons>
            <ButtonStyled
              color="success"
              variant="outlined"
              startIcon={<ThumbUpOffAlt />}
              aria-label="down"
              onClick={onCorrectReviewClick}
              disabled={buttonsDisabled}
              className={action?.value === ActionType.CORRECT ? 'active' : ''}
            >
              {t('correctReview')}
            </ButtonStyled>
            <ButtonStyled
              color="warning"
              variant="outlined"
              startIcon={<ThumbDownOffAlt />}
              aria-label="down"
              onClick={() => setReviewIssueActive(true)}
              disabled={buttonsDisabled}
              className={action?.value === ActionType.INCORRECT ? 'active' : ''}
            >
              {t('reviewIssue')}
            </ButtonStyled>
            <ButtonStyled
              color="info"
              variant="outlined"
              startIcon={<ErrorOutline />}
              aria-label="down"
              onClick={handleEscalateReview}
              disabled={buttonsDisabled}
            >
              {t('escalateReview')}
            </ButtonStyled>
            <CloseButton
              variant="contained"
              onClick={handleCloseButton}
              aria-label="down"
              disabled={buttonsDisabled}
            >
              {t('close')}
            </CloseButton>
          </Buttons>
          {feedbackReceived ? (
            <Typography variant="body2">{t('feedbackReceived')}</Typography>
          ) : null}
        </div>
      ) : (
        <ReviewIssue
          handleReviewIssue={handleReviewIssue}
          setReviewIssueActive={(value: boolean) => setReviewIssueActive(value)}
          reviewRaw={reviewRaw}
        />
      )}
    </ActionsStyled>
  );
};

export default Actions;
