import {
  Button,
  buttonClasses,
  styled,
  Typography,
  typographyClasses,
} from '@mui/material';

const ButtonCore = styled(Button)(() => ({
  textTransform: 'none',
  margin: '0 4px',
  fontSize: '1.2rem',
  borderRadius: 5,
  width: 'calc((100% - 24px) / 4)',
  padding: '6px 15px',
  marginBottom: 10,

  '&:first-of-type, &:nth-of-type(5)': {
    marginLeft: 0,
  },
  '&:last-of-type, &:nth-of-type(4)': {
    marginRight: 0,
  },
}));

export const Buttons = styled('div')(() => ({
  marginTop: 20,
  marginBottom: -10,
}));

export const ButtonStyled = styled(ButtonCore)(
  ({ theme: { palette }, color }) => ({
    border: `1px solid ${palette.primary.main}`,
    color: palette.text.primary,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.06)',

    [`.${buttonClasses.startIcon} > svg`]: {
      fontSize: '1.3rem !important',
      color: color ? palette[color].main : palette.text.primary,
    },

    '&.active': {
      fontWeight: 'bold',
      borderColor: color ? palette[color].main : palette.primary.main,
      color: color ? palette[color].main : palette.primary.main,
    },
  })
);

export const CloseButton = styled(ButtonCore)(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.dark,
  color: palette.common.white,
  fontWeight: 'bold',
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h3}`]: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));
