import { Pagination as MuiPagination } from '@mui/material';
import { styled } from '@mui/system';
import useTable from 'hooks/useTable';
import { ChangeEvent, FC, useEffect } from 'react';
import { connectPagination } from 'react-instantsearch-dom';

import { PaginationProps } from './models';

const PaginationContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 345,
}));

const Pagination: FC<PaginationProps> = ({
  refine,
  nbPages,
  currentRefinement,
}) => {
  const { currentPage, setPage } = useTable();

  useEffect(() => {
    refine(currentPage);
  }, [currentPage, refine]);

  const onPageClick = (_event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  return (
    <PaginationContainer>
      {nbPages > 0 ? (
        <MuiPagination
          count={nbPages}
          variant="outlined"
          shape="rounded"
          onChange={onPageClick}
          page={currentRefinement}
        />
      ) : null}
    </PaginationContainer>
  );
};

const AlgoliaPagination = connectPagination(Pagination);

export default AlgoliaPagination;
