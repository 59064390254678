import { Grid, Typography, typographyClasses } from '@mui/material';
import { styled } from '@mui/system';
import ColumnsSelector from 'components/ColumnsSelector';
import ColumnsSwitcher from 'components/ColumnsSwitcher';
import Logo from 'components/common/Logo';
import { LOGO_VARIANT } from 'components/common/Logo/constants';
import ExportView from 'components/ExportView';
import useIndiceStats from 'hooks/useIndiceStats';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ConfigDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [`.${typographyClasses.h3}`]: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  [`.${typographyClasses.subtitle1}`]: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: theme.palette.text.secondary,

    span: {
      color: theme.palette.text.primary,
    },
  },
}));

const ConfigButtons = styled('div')(() => ({
  '> div, > button': {
    margin: '0 5px',

    '&:last-child': {
      marginRight: 30,
    },
  },
}));

const TableConfig: FC = () => {
  const { t } = useTranslation();
  const { stats } = useIndiceStats();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      margin="10px 0"
    >
      <ConfigDescription>
        <Logo variant={LOGO_VARIANT.LOGO_SECONDARY} />
        <div>
          <Typography variant="h3">{t('productOpinionSearch')}</Typography>
          <Typography variant="subtitle1">
            <span>{stats.totalEntries}</span> {t('reviewsInTotal')}
          </Typography>
        </div>
      </ConfigDescription>
      <ConfigButtons>
        <ColumnsSwitcher columns={['original_review', 'review']} />
        <ExportView />
        <ColumnsSelector />
      </ConfigButtons>
    </Grid>
  );
};

export default TableConfig;
