import LIST_OMITED_HITS from 'constants/listOmitedHits';
import { DEFAULT_TABLE_CONFIG } from 'constants/table';
import { TableContext } from 'contexts/TableContext';
import { convertToExcel } from 'helpers/convertToExcel';
import { buildIndiceName } from 'helpers/defaultSorting';
import { handleError } from 'helpers/errorHandler';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlgoliaHit, Review } from 'types/algolia';
import {
  ColumnConfig,
  ColumnName,
  SortOrder,
  TableConfig,
} from 'types/tableConfig';

import { Cell, ClearColumnConfig, Column, UseTable } from './models';

const useTable: UseTable = () => {
  const {
    columns,
    localStates,
    sorting,
    setSorting,
    setCurrentPage,
    currentPage,
    updateColumns,
    updateLocalState,
    loading,
    setLoading: setLoadingContext,
    updateLoadedHits,
    loadedHits,
  } = useContext(TableContext);

  const { t } = useTranslation();
  const sortedColumns = useMemo(
    () =>
      sortBy(
        Object.entries(DEFAULT_TABLE_CONFIG),
        ([, { order }]) => order
      ) as [ColumnName, ColumnConfig][],
    []
  );

  const sortedModalColumns = useMemo(
    () =>
      sortBy(
        Object.entries(DEFAULT_TABLE_CONFIG).filter(([, { modal }]) =>
          Boolean(modal)
        ) as [ColumnName, ColumnConfig][],
        ([, { modal }]) => modal?.order
      ),
    []
  );

  const getColumnsHeaders = () => sortedColumns.map(([name]) => name);

  const getAllColumns = useCallback(
    () =>
      sortedColumns.map(([columnName]) => ({
        columnName,
        visible: columns[columnName].visible,
      })),
    [columns, sortedColumns]
  );

  const extractColumnConfig = (config: ColumnConfig): ClearColumnConfig => {
    const params = cloneDeep(config);

    delete params.valueFn;
    delete params.filter;

    return params;
  };

  const getColumnsConfig = (): Column[] =>
    sortedColumns.map(([columnName]) => ({
      columnName,
      ...extractColumnConfig(columns[columnName]),
    }));

  const concatTemplateWithData = useCallback(
    (templateColumns: [ColumnName, ColumnConfig][], row: Review) => {
      const override = localStates[row.objectID];

      try {
        return templateColumns.map(([columnName, { valueFn }]) => {
          const customValue = valueFn?.(row);
          const originalValue = row[columnName];
          const overrideColumn = override?.[columnName];
          const value = customValue !== undefined ? customValue : originalValue;

          return {
            columnName,
            value: overrideColumn !== undefined ? overrideColumn : value,
            ...extractColumnConfig(columns[columnName]),
          };
        });
      } catch (error) {
        handleError(error);

        return [];
      }
    },
    [columns, localStates]
  );

  const prepareRowToDisplay = useCallback(
    (row: Review): Cell[] => concatTemplateWithData(sortedColumns, row),
    [concatTemplateWithData, sortedColumns]
  );

  const prepareRowToDisplayInModal = useCallback(
    (row: Review): Cell[] => concatTemplateWithData(sortedModalColumns, row),
    [concatTemplateWithData, sortedModalColumns]
  );

  const sortByColumn = (column: string) => {
    const indice = columns[column].sortReplicaIndice as string;

    if (sorting?.column === column) {
      const direction =
        sorting.direction === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;

      setSorting({
        ...sorting,
        indice: buildIndiceName(indice, direction),
        direction,
      });

      return;
    }
    setSorting({
      column,
      indice: buildIndiceName(indice),
      direction: SortOrder.ASC,
    });
  };

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setLoading = useCallback((value: boolean) => {
    setLoadingContext(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareFiltersToDisplay = () => {
    const filterComponents = Object.entries(columns).filter(
      ([, { filter }]) => filter
    );
    const sortedFilters = sortBy(
      filterComponents,
      (elem) => elem[1].filter?.order
    );

    const filterData = sortedFilters.map(([filterName, filterProps]) => ({
      name: filterName,
      Component: filterProps?.filter?.component,
      items: filterProps?.filter?.items,
    }));

    return filterData;
  };

  const showHideColumns = (
    columnsToShow: string[],
    columnsToHide: string[]
  ) => {
    const fieldsToUpdate = Object.entries(columns).reduce(
      (result, [key, { visible, ...rest }]) => {
        const shouldShowColumn = columnsToShow.includes(key) && !visible;
        const shouldHideColumn = columnsToHide.includes(key) && visible;

        if (shouldShowColumn || shouldHideColumn) {
          return {
            ...result,
            [key]: {
              ...rest,
              visible: !visible,
            },
          };
        }

        return result;
      },
      {} as TableConfig
    );
    updateColumns(fieldsToUpdate);
  };

  const setLoadedHits = (hits: AlgoliaHit[]) => {
    updateLoadedHits(hits);
  };

  const exportToExcel = () => {
    const filteredHits = loadedHits?.map((elem) =>
      omit(elem, LIST_OMITED_HITS)
    );
    const headersTitles =
      filteredHits && Object.keys(filteredHits[0])?.map((elem) => t(elem));

    convertToExcel(filteredHits, headersTitles);
  };

  return {
    getColumnsHeaders,
    prepareRowToDisplay,
    getColumnsConfig,
    sortByColumn,
    sorting,
    currentPage,
    setPage,
    prepareFiltersToDisplay,
    prepareRowToDisplayInModal,
    showHideColumns,
    getAllColumns,
    updateLocalState,
    localStates,
    loading,
    setLoading,
    setLoadedHits,
    exportToExcel,
  };
};

export default useTable;
