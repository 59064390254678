import { useCallback, useEffect, useRef, useState } from 'react';

import { UseIsElementVisibleResult } from './models';

const useIsElementVisible = <
  T extends HTMLElement
>(): UseIsElementVisibleResult<T> => {
  const sentiel = useRef<T>(null);
  const [isVisible, setIsVisible] = useState(false);

  const onSentielIntersection = useCallback((entries) => {
    const visible = entries.some(({ isIntersecting }) => isIntersecting);
    setIsVisible(visible);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(onSentielIntersection);
    if (sentiel.current) {
      observer.observe(sentiel.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, [onSentielIntersection, sentiel]);

  return { sentiel, isVisible };
};

export default useIsElementVisible;
