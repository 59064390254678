import { ContentCopy } from '@mui/icons-material';
import { Typography, typographyClasses } from '@mui/material';
import { styled } from '@mui/system';
import Logo from 'components/common/Logo';
import { LOGO_VARIANT } from 'components/common/Logo/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStyled } from '../CommonStyles';
import { HeaderProps } from './models';

const StyledHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 20,

  '.flex': {
    display: 'flex',
    alignItems: 'center',
  },

  [`.${typographyClasses.h1}`]: {
    fontSize: '2.2rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  [`.${typographyClasses.subtitle1}`]: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: theme.palette.text.secondary,
  },

  '.logo': {
    marginRight: 30,

    svg: {
      width: 86,
      height: 73,
    },
  },
}));

const Button = styled(ButtonStyled)(() => ({
  width: 110,
}));

const Header: FC<HeaderProps> = ({ reviewRaw }) => {
  const {
    retailer_product_code: RetailerProductCode,
    market,
    brand,
    category,
  } = reviewRaw || {};
  const { t } = useTranslation();

  const onCopyLinkClick = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <StyledHeader>
      <div className="flex">
        <Logo variant={LOGO_VARIANT.LOGO_SECONDARY} />
        <div className="header-info">
          <Typography variant="h1">{RetailerProductCode}</Typography>
          <Typography variant="subtitle1">
            <span>
              {market} | {brand} | {category} | {RetailerProductCode}
            </span>
          </Typography>
        </div>
      </div>
      <div>
        <Button
          color="info"
          variant="outlined"
          startIcon={<ContentCopy />}
          aria-label="down"
          onClick={onCopyLinkClick}
        >
          {t('copyLink')}
        </Button>
      </div>
    </StyledHeader>
  );
};

export default Header;
