import { styled } from '@mui/material/styles';
import MaterialTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { FC } from 'react';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  height: `calc(100% - ${(theme as any).sizes.headerHeight}px)`,
  width: '100%',
}));

export const Table: FC = ({ children }) => (
  <StyledTableContainer>
    <MaterialTable stickyHeader aria-label="sticky table">
      {children}
    </MaterialTable>
  </StyledTableContainer>
);

export default Table;
