import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from '@mui/material';
import { FC } from 'react';

import { ValidationProps } from './models';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '6px',
  borderRadius: 3,

  [`${linearProgressClasses.bar}`]: {
    borderRadius: '3px',
  },
}));

const percentageStyles = { fontSize: '1rem', fontWeight: 'bold' };

export const Validation: FC<ValidationProps> = ({ value }) => {
  const percentageValue = parseFloat(value) * 100;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '45px', mr: 1 }}>
        <StyledLinearProgress
          color={percentageValue >= 70 ? 'success' : 'warning'}
          variant="determinate"
          value={percentageValue}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={percentageStyles}>{`${Math.round(
          percentageValue
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
