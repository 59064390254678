import { styled } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SubHeader } from '../CommonStyles';
import { HeaderColumnsProps } from './models';

const Cells = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',

  section: {
    width: '20%',
    marginBottom: 20,
    padding: '0 15px',
    color: theme.palette.text.primary,

    '&:first-of-type, &:nth-of-type(6)': {
      paddingLeft: 0,
    },

    '&:last-of-type, &:nth-of-type(5)': {
      paddingRight: 0,
    },
  },
}));

const HeaderColumns: FC<HeaderColumnsProps> = ({ columns }) => {
  const { t } = useTranslation();

  return (
    <Cells>
      {columns.map(
        ({
          columnName,
          customRenderer: CustomRenderer,
          value,
          customClasses,
        }) => (
          <section key={columnName} className={customClasses}>
            <SubHeader variant="h3">{t(columnName)}</SubHeader>
            {CustomRenderer ? <CustomRenderer visible value={value} /> : value}
          </section>
        )
      )}
    </Cells>
  );
};

export default HeaderColumns;
