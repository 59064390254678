import algoliasearch from 'algoliasearch';
import { TableContext } from 'contexts/TableContext';
import { useCallback, useContext } from 'react';

import { UseIndiceStats } from './models';

const useIndiceStats: UseIndiceStats = () => {
  const { stats } = useContext(TableContext);

  const getMainIndice = useCallback(async () => {
    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID as string,
      process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
    );
    const indices = await client.listIndices();

    return indices.items.find(
      ({ name }) => name === process.env.REACT_APP_ALGOLIA_INDEX
    );
  }, []);

  const getStats = useCallback(async () => {
    try {
      const indice = await getMainIndice();
      if (!indice) {
        return null;
      }

      return {
        totalEntries: indice.entries,
        lastUpdateDatetime: new Date(indice.updatedAt),
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      return null;
    }
  }, [getMainIndice]);

  return {
    getStats,
    stats,
  };
};

export default useIndiceStats;
