import { styled } from '@mui/system';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { UrlProps } from './models';

const A = styled('a')(({ theme }) => ({
  color: theme.palette.action.active,
}));

export const Url: FC<UrlProps> = ({ value }) => {
  const { t } = useTranslation();

  const onUrlClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return (
    <A href={value} target="_blank" rel="noreferrer" onClick={onUrlClick}>
      {t('link')}
    </A>
  );
};
