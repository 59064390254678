import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { CurrentRefinementsProvided } from 'react-instantsearch-core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const StyledIcon = styled(FilterAltOffIcon)(({ theme }) => ({
  cursor: 'pointer',
  margin: '0 10px 0 !important',
  color: theme.palette.primary.dark,
  alignSelf: 'flex-end',

  '&:hover': {
    color: theme.palette.info.light,
  },
}));

export const ClearFilters: FC<CurrentRefinementsProvided> = ({
  items,
  refine,
}) => {
  const handleClick = () => {
    if (items.length) {
      refine(items);
    }
  };

  return <StyledIcon onClick={handleClick} />;
};

export default connectCurrentRefinements(ClearFilters);
