import './App.scss';

import CssBaseline from '@mui/material/CssBaseline';
import SecureRoute from 'components/common/SecureRoute/SecureRoute';
import AuthContextProvider from 'contexts/AuthContext';
import ConfigContextProvider from 'contexts/ConfigContext';
import TableContextProvider from 'contexts/TableContext';
import ThemeContextProvider from 'contexts/ThemeContext';
import { Home } from 'pages/Home';
import { Route, Routes } from 'react-router-dom';

const App = () => (
  <ConfigContextProvider>
    <CssBaseline />
    <ThemeContextProvider>
      <AuthContextProvider>
        <TableContextProvider>
          <Routes>
            <Route element={<SecureRoute />}>
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </TableContextProvider>
      </AuthContextProvider>
    </ThemeContextProvider>
  </ConfigContextProvider>
);

export default App;
