const LIST_OMITED_HITS = [
  '_highlightResult',
  '__position',
  'objectID',
  'ReviewTimestamp',
  'PullTimestamp',
  'id',
  'Probability_of_being_Adverse Event',
  'Probability_of_being_Lack of Effect',
  'Probability_of_being_Not Adverse Event',
  'RBBrandKey',
  'translated_review_text',
  'translated_review_title',
];

export default LIST_OMITED_HITS;
