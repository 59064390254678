import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel, {
  tableSortLabelClasses,
} from '@mui/material/TableSortLabel';
import classNames from 'classnames';
import Table from 'components/common/Table';
import { Row } from 'components/common/Table/Row';
import ToolTip from 'components/common/Tooltip';
import useTable from 'hooks/useTable';
import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connectHits, connectStateResults } from 'react-instantsearch-dom';
import { useNavigate } from 'react-router-dom';
import { AlgoliaHit, AlgoliaHitsResponse, Review } from 'types/algolia';
import { MuiSortType } from 'types/mui';
import { SortOrder } from 'types/tableConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.background.paper,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.2rem',
  padding: '5px 18px',

  '&:first-of-type': {
    borderRadius: '5px 0 0 0',
  },
  '&:last-of-type': {
    borderRadius: '0 5px 0 0',
    position: 'sticky',
    right: -1,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.common.white,

    [`.${tableSortLabelClasses.icon}`]: {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
  },
  [`.${tableSortLabelClasses.icon}`]: {
    fontSize: '2.4rem',
    opacity: 1,
    color: theme.palette.background.paper,
  },

  [`&.${tableSortLabelClasses.active}`]: {
    color: theme.palette.common.white,

    [`.${tableSortLabelClasses.icon}`]: {
      color: theme.palette.secondary.main,
    },
  },
}));

const LoaderRow = styled('tr')(() => ({
  height: 91,
  textAlign: 'center',
}));

export const Hits: FC<AlgoliaHitsResponse> = ({ hits, searching }) => {
  const {
    getColumnsConfig,
    sortByColumn,
    sorting,
    prepareRowToDisplay,
    loading,
    setLoading,
    setLoadedHits,
  } = useTable();
  const columnConfig = getColumnsConfig();
  const { t } = useTranslation();
  const [lastHits, setLastHits] = useState<AlgoliaHit[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (hits && !isEqual(lastHits, hits)) {
      setLastHits(hits);
      setLoadedHits(hits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits]);

  useEffect(() => {
    setLoading(searching as boolean);
  }, [searching, setLoading]);

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`/review/${id}`);
    },
    [navigate]
  );

  const renderRows = useMemo(
    () =>
      lastHits?.map((row) => (
        <Row
          onClick={() => onRowClick(row.objectID)}
          key={row.id}
          row={prepareRowToDisplay(row)}
          reviewRaw={row}
        />
      )),
    [lastHits, onRowClick, prepareRowToDisplay]
  );

  return (
    <Table>
      <colgroup>
        {columnConfig.map(({ width, columnName, visible }) => (
          <col
            key={columnName}
            style={{ minWidth: width }}
            className={classNames({ hidden: !visible })}
          />
        ))}
      </colgroup>
      <TableHead>
        <TableRow>
          {columnConfig.map(
            ({
              columnName,
              sortReplicaIndice,
              visible,
              tooltipTranslationKey,
            }) => {
              const sortActive = Boolean(sorting?.column === columnName);
              const direction =
                sortActive && sorting?.direction !== undefined
                  ? (SortOrder[sorting.direction].toLowerCase() as MuiSortType)
                  : 'desc';

              return (
                <StyledTableCell
                  key={columnName}
                  sortDirection={direction}
                  className={classNames({ hidden: !visible })}
                >
                  {sortReplicaIndice ? (
                    <StyledTableSortLabel
                      IconComponent={ArrowDropDownIcon}
                      active={sortActive}
                      direction={direction}
                      onClick={() => {
                        sortByColumn(columnName);
                      }}
                    >
                      {t(columnName)}
                      {tooltipTranslationKey ? (
                        <ToolTip
                          type="help"
                          tooltipText={tooltipTranslationKey}
                        />
                      ) : null}
                    </StyledTableSortLabel>
                  ) : (
                    <>
                      {t(columnName)}
                      {tooltipTranslationKey ? (
                        <ToolTip
                          type="help"
                          tooltipText={tooltipTranslationKey}
                        />
                      ) : null}
                    </>
                  )}
                </StyledTableCell>
              );
            }
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <LoaderRow>
            <td colSpan={columnConfig.length}>
              <CircularProgress color="secondary" size={50} />
            </td>
          </LoaderRow>
        ) : null}
        {!loading && renderRows.length > 0 ? renderRows : null}
        {!loading && renderRows.length === 0 ? (
          <tr>
            <td colSpan={columnConfig.length}>
              <Typography>{t('noResultsFound')}</Typography>
            </td>
          </tr>
        ) : null}
      </TableBody>
    </Table>
  );
};

const AlgoliaTable = connectHits<AlgoliaHitsResponse, Review>(
  connectStateResults<AlgoliaHitsResponse>(Hits)
);

export default AlgoliaTable;
