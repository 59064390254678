import { utils, writeFile } from 'xlsx';

export const convertToExcel = (value, headers) => {
  const workBook = utils.book_new();
  const jsonToSheet = utils.json_to_sheet(value);
  utils.sheet_add_aoa(jsonToSheet, [headers]);

  utils.book_append_sheet(workBook, jsonToSheet, 'SheetFeedbacks');

  const exportTime = new Date().toLocaleDateString();
  writeFile(workBook, `Feedbacks-${exportTime}.xlsx`);
};
