import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsableProps } from './models';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.secondary.dark,
  fontWeight: 'bold',
  fontSize: '1rem',
  padding: 0,
  marginTop: -3,
  minWidth: 'unset',
}));

const Div = styled('div')(() => ({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'keep-all',
  overflow: 'hidden',
  hyphens: 'auto',
  textAlign: 'left',

  '&.expanded': {
    display: 'block',
  },
}));

export const Collapsable: FC<CollapsableProps> = ({ value, visible }) => {
  const [expanded, setExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const { t } = useTranslation();

  const cell = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!cell?.current) {
      return;
    }
    setIsClamped(cell.current.scrollHeight > cell.current.clientHeight);
  }, [cell, visible]);

  const onExpandClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setExpanded((exp) => !exp);
  };

  const expandButton = (
    <StyledButton size="small" variant="text" onClick={onExpandClick}>
      {expanded ? t('readLess') : t('readMore')}
    </StyledButton>
  );

  return (
    <div>
      <Div ref={cell} className={expanded ? 'expanded' : ''}>
        {value}
      </Div>
      {isClamped ? expandButton : null}
    </div>
  );
};
