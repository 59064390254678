import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { styled } from '@mui/material/styles';
import Logo from 'components/common/Logo';
import useIndiceStats from 'hooks/useIndiceStats';
import { useTranslation } from 'react-i18next';

const StyledHeader = styled('div')(({ theme }) => ({
  height: '86px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'space-between',
  margin: '0 -15px',

  '.top-header': {
    maxHeight: '71px',
    display: 'flex',
    padding: '10px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.08)',
    borderRadius: '0px 0px 15px 15px',
    background: theme.palette.background.paper,

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
}));

const StyledRefreshSection = styled('div')(({ theme }) => ({
  alignItems: 'center',
  height: '30px',
  marginRight: '10px',
  fontFamily: 'Energy',

  p: {
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'normal',
    fontSize: '1.2rem',
  },

  svg: {
    marginRight: '10px',
    color: theme.palette.secondary.main,
  },
}));

const StyledLogo = styled('div')(({ theme }) => ({
  h2: {
    color: theme.palette.primary.dark,
    fontSize: '18px',
    marginLeft: '10px',
  },
}));

const Header = () => {
  const { t } = useTranslation();
  const { stats } = useIndiceStats();

  return (
    <StyledHeader>
      <div className="top-header">
        <StyledLogo>
          <Logo />
          <h2>{t('headerName')}</h2>
        </StyledLogo>
        <StyledRefreshSection>
          <CalendarMonthIcon color="primary" />
          <p>
            <span>{t('refresh')}</span>
            <span>{stats.lastUpdateDatetime.toLocaleString()}</span>
          </p>
        </StyledRefreshSection>
      </div>
    </StyledHeader>
  );
};
export default Header;
