import { styled } from '@mui/system';
import { ReactComponent as LogoMain } from 'assets/images/logoMain.svg';
import { ReactComponent as LogoSecondary } from 'assets/images/logoSecondary.svg';
import { FC } from 'react';

import { LOGO_VARIANT } from './constants';
import { LogoProps } from './models';

const LogoComponent = {
  [LOGO_VARIANT.LOGO_MAIN]: LogoMain,
  [LOGO_VARIANT.LOGO_SECONDARY]: LogoSecondary,
};

const Logo: FC<LogoProps> = ({ variant = LOGO_VARIANT.LOGO_MAIN, size }) => {
  const StyledLogoComponent = styled(LogoComponent[variant])(() => ({
    width: size,
  }));

  return (
    <div className="logo">
      <StyledLogoComponent />
    </div>
  );
};

export default Logo;
