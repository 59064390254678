import { formatISO, getUnixTime, setHours } from 'date-fns';

export const convertTimestampToDate = (value: number): string =>
  value ? formatISO(new Date(value * 1e3), { representation: 'date' }) : '';

export const convertTimestamp = (value: number): string => // should be changed after BE fixes
  value ? formatISO(new Date(value), { representation: 'date' }) : '';

export const convertDateToTimestamp = (
  value: Date,
  isMin?: boolean
): number => {
  if (isMin) {
    // date hours're reseted to show dates from start of x date till end of y date
    return getUnixTime(new Date(setHours(value, 0)));
  }

  return getUnixTime(new Date(setHours(value, 24)));
};
