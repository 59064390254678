import { AuthContext } from 'contexts/AuthContext';
import { handleError } from 'helpers/errorHandler';
import { useCallback, useContext } from 'react';

import {
  GetAppDetailsResponse,
  GetUserInfoResponse,
  IsAuthenticatedResponse,
  UseAuth,
} from './models';

const useAuth: UseAuth = () => {
  const { users, currentUser } = useContext(AuthContext);

  const getAppDetails =
    useCallback(async (): Promise<GetAppDetailsResponse> => {
      try {
        const appDetailsResponse = await fetch(
          `${process.env.REACT_APP_AIDE_API_URL}api/mlappmeta?app_handle=adversify`
        );

        if (appDetailsResponse.ok) {
          return (await appDetailsResponse.json()) as GetAppDetailsResponse;
        }

        return {
          status: {
            statusCode: appDetailsResponse.status.toString(),
            statusMessage: appDetailsResponse.statusText,
          },
        };
      } catch (error) {
        handleError(error);

        return {
          status: {
            statusCode: '500',
            statusMessage: 'An error occurred',
          },
        };
      }
    }, []);

  const isAuthenticatedRequest =
    useCallback(async (): Promise<IsAuthenticatedResponse> => {
      const isAuthenticatedResponse = await fetch(
        `${process.env.REACT_APP_AIDE_API_URL}api/isauthenticated`
      );

      if (isAuthenticatedResponse.ok) {
        return (await isAuthenticatedResponse.json()) as IsAuthenticatedResponse;
      }

      return {
        status: {
          statusCode: isAuthenticatedResponse.status.toString(),
          statusMessage: isAuthenticatedResponse.statusText,
        },
      };
    }, []);

  const getUserInfoRequest =
    useCallback(async (): Promise<GetUserInfoResponse> => {
      const userInfoUrl = `${process.env.REACT_APP_AIDE_API_URL}api/userinfo?post_sign_in_url=${window.location.origin}`;
      const getUserInfoResponse = await fetch(userInfoUrl, {
        credentials: 'include',
      });

      if (getUserInfoResponse.ok) {
        return (await getUserInfoResponse.json()) as GetUserInfoResponse;
      }

      return {
        status: {
          statusCode: getUserInfoResponse.status.toString(),
          statusMessage: getUserInfoResponse.statusText,
        },
      };
    }, []);

  const isAuthorisedRequest =
    useCallback(async (): Promise<IsAuthenticatedResponse> => {
      const authoriseUrl = `${process.env.REACT_APP_AIDE_API_URL}api/isauthorised?post_sign_in_url=${window.location.origin}`;
      const isAuthorisedResponse = await fetch(authoriseUrl);

      if (isAuthorisedResponse.ok) {
        return (await isAuthorisedResponse.json()) as IsAuthenticatedResponse;
      }

      return {
        status: {
          statusCode: isAuthorisedResponse.status.toString(),
          statusMessage: isAuthorisedResponse.statusText,
        },
      };
    }, []);

  const redirectToSignIn = useCallback(() => {
    window.location.href = `${window.location.href}auth/sign_in?post_sign_in_url=${window.location.origin}`;
  }, []);

  const getUserInfo = useCallback(async () => {
    const authenticatedPromise = isAuthenticatedRequest();
    const authorisedPromise = isAuthorisedRequest();
    const getUserPromise = getUserInfoRequest();

    try {
      const [authenticated, authorised, getUser] = await Promise.all([
        authenticatedPromise,
        authorisedPromise,
        getUserPromise,
      ]);

      if (
        !authenticated.data?.is_authenticated ||
        !authorised.data?.is_authenticated ||
        !getUser.data
      ) {
        redirectToSignIn();

        return null;
      }

      return {
        ...getUser.data,
        is_authenticated: authenticated.data?.is_authenticated,
        is_authorised: authorised.data?.is_authenticated,
      };
    } catch (error) {
      handleError(error);
      redirectToSignIn();

      return null;
    }
  }, [
    isAuthenticatedRequest,
    isAuthorisedRequest,
    redirectToSignIn,
    getUserInfoRequest,
  ]);

  return { users, currentUser, getUserInfo, getAppDetails };
};

export default useAuth;
