import { ConfigContext } from 'contexts/ConfigContext';
import { handleApiRequest } from 'helpers/apiHandler';
import { handleError } from 'helpers/errorHandler';
import { useCallback, useContext } from 'react';

import { GetLabelsResult, UseConfig } from './models';

const useConfig: UseConfig = () => {
  const { predictions, predictionsLevel2 } = useContext(ConfigContext);

  const getPredictions = useCallback(async () => {
    const result = await handleApiRequest<GetLabelsResult>('labels', 'GET');

    if (result.success) {
      return result as GetLabelsResult;
    }

    handleError(result.message);

    return [];
  }, []);

  return { getPredictions, predictions, predictionsLevel2 };
};

export default useConfig;
