import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { Outlet } from 'react-router-dom';

const StyledBlock = styled('div')(() => ({
  textAlign: 'center',
  height: '100vh',
  paddingTop: '200px',
  zIndex: 9999,
  position: 'relative',
  background: 'rgba(0,0,0,0.4)',
  transition: 'background-color ease-in-out .2s',
}));

export const SecureRoute = () => {
  const { currentUser } = useAuth();

  if (
    !currentUser ||
    !currentUser.is_authenticated ||
    !currentUser.is_authorised
  ) {
    return (
      <StyledBlock>
        <CircularProgress size={100} />
      </StyledBlock>
    );
  }

  return <Outlet />;
};

export default SecureRoute;
